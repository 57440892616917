
import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import OfferCard from './../components/cards/OfferCard'
import { scrollToTop } from '../utils/function'

function AllOffers(props) {

    const image_path = process.env.REACT_APP_IMAGE
    
    const [packagee, setPackagee] = useState([]);
    
    const getData = async () => {
        try {
            const apipath = process.env.REACT_APP_API_URL

            const result3 = await axios.get(`${apipath}offer/all`);
            const data3 = await result3.data;
            setPackagee(data3.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData()
    }, [])
    
    return (
        <>
           <Container className="mt-4 pt-4">
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Explore Great Places</h5>
                            <h2 className="text-primary text-uppercase">Special Offers</h2>
                            <p>{props.data.contactData.description_offer}</p>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-1 g-4">
                    {
                        packagee.map((itemm, keyy) =>
                            <OfferCard key={keyy} itemm={itemm} />
                        )
                    }
                </Row>

            </Container>
        </>
    )
}

export default AllOffers
import { Card, Col, Container, Row } from "react-bootstrap"
import { Button, Form, Stack } from "react-bootstrap"
import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
import { scrollToTop } from '../utils/function'

import { useNavigate } from "react-router-dom";

import PopularDestination from "../components/PopularDestination"
import PackageCard from '../components/cards/PackageCard'
import ActivityCard from '../components/cards/ActivityCard'
import OfferCard from '../components/cards/OfferCard'

function Home(props) {

    const navigate = useNavigate();

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => { setIndex(selectedIndex); };

    const image_path = process.env.REACT_APP_IMAGE

    const [banner, setBanner] = useState([]);
    //const [destination, setDestination] = useState([]);
    const [packagee, setPackagee] = useState([]);
    //const [activity, setActivity] = useState([]);
    const [hotel, setHotel] = useState([]);
    const [offer, setOffer] = useState([]);
    const [testimonial, setTestimonial] = useState([]);

    const getData = async () => {
        try {
            const apipath = process.env.REACT_APP_API_URL

            const result1 = await axios.get(`${apipath}banner/all`);
            const data1 = await result1.data;
            setBanner(data1.data);

            // const result2 = await axios.get(`${apipath}destination/all`);
            // const data2 = await result2.data;
            // setDestination(data2.data);

            const result3 = await axios.get(`${apipath}package/all?limit=3`);
            const data3 = await result3.data;
            setPackagee(data3.data);

            // const result4 = await axios.get(`${apipath}activity/all?limit=6`);
            // const data4 = await result4.data;
            // setActivity(data4.data);

            const result5 = await axios.get(`${apipath}offer/all?limit=3`);
            const data5 = await result5.data;
            setOffer(data5.data);

            const result6 = await axios.get(`${apipath}testimonial/all`);
            const data6 = await result6.data;
            setTestimonial(data6.data);

            const result7 = await axios.get(`${apipath}hotel/all_destinationwise`);
            const data7   = await result7.data;
            setHotel(data7.data);
        }
        catch (error) {
        }
    }

    const handleChange = (e) => {
        var destid = document.getElementById('dest').value;
        navigate('/packages/destination/'+destid);
      };

    useEffect(() => {
        scrollToTop()
        getData()
    }, [])

    return (
        <>
            {/* Banners */}
            <Carousel activeIndex={index} onSelect={handleSelect}>
                {
                    banner.map((itemm, keyy) =>
                        <Carousel.Item  key={keyy}>
                            <img
                                className="d-block w-100"
                                src={image_path + itemm.image}
                                alt={itemm.title}
                            />
                        </Carousel.Item>
                    )
                }
            </Carousel>

            {/*  Search */}
            <Container className="home-search-panel">
                <Row>
                    <Col>
                        <Stack direction="horizontal" gap={3}>
                            <div>
                                <h4 className="text-primary">Search Destination</h4>
                                <p>Grab best deals on Top Destinations and Enjoy your Dream Holiday in budget.</p>
                            </div>
                            <select className="form-control" name="dest" id="dest">
                                <option>Choose Destination</option>
                                {
                                    props.data.destinationData.map((itemm, key) =>
                                        <option value={itemm.id}>{itemm.title}</option>
                                    )
                                }
                            </select>
                            <Button variant="secondary" onClick={handleChange} >Search</Button>
                        </Stack>
                    </Col>
                </Row>
            </Container>

            {/* Destinations */}
            <Container className="mt-4 pt-4">
                <PopularDestination alldata={props} destination={props.data.destinationData} />
            </Container>

            {/*  Packages */}
            <Container className="mt-4 pt-4">
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Explore Great Places</h5>
                            <h2 className="text-primary text-uppercase">Popular Packages</h2>
                            <p>{props.data.contactData.description_package}</p>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-1 g-4">
                    {
                        packagee.map((itemm, key) =>
                            <PackageCard key={key} itemm={itemm} />
                        )
                    }
                </Row>
                <Row className="mt-4 pt-4 mb-4">
                    <Col className="text-center">
                        <Link to='/all-packages' className="view-all-btn">View All Packages</Link>
                    </Col>
                </Row>
            </Container>

            {/*  Activity */}
            <Container className="mt-4 pt-4">
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">TRAVEL BY ACTIVITY</h5>
                            <h2 className="text-primary text-uppercase">Adventure & Activity</h2>
                            <p>{props.data.contactData.description_activity}</p>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    {
                        props.data.activityData.map((itemm, keyy) =>
                            <>
                            {itemm.destinations_count>0 && 
                                <ActivityCard key={keyy} itemm={itemm} />
                            }
                            </>
                            
                        )
                    }
                </Row>
                
            </Container>

            {/* Hotels */}
            <Container className="mt-4 pt-4">
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Luxury in Affordable Prices</h5>
                            <h2 className="text-primary text-uppercase">Best Hotel Deals</h2>
                            <p>{props.data.contactData.description_hotel}</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        hotel.map((itemm, keyy) =>
                            <>
                                {itemm.hotels_count>0 && 
                                <Col md={4}>
                                    <Link to={`/hotels/destination/`+itemm.id}>
                                        <div className="hotelName">Hotels in {itemm.title}</div>
                                    </Link>
                                </Col>}
                            </>
                        )
                    }
                </Row>
            </Container>

            {/*  Offers */}
            <Container className="mt-4 pt-4 pb-4">
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Travel Offers & Discount</h5>
                            <h2 className="text-primary text-uppercase">Special Travel Offer</h2>
                            <p>{props.data.contactData.description_offer}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-1 g-4">
                    {
                        offer.map((itemm, keyy) =>
                            <OfferCard key={keyy} itemm={itemm} />
                        )
                    }
                </Row>
                <Row className="mt-4 pt-4 mb-4">
                    <Col className="text-center">
                        <Link to='/all-offers' className="view-all-btn">View All Offers</Link>
                    </Col>
                </Row>
            </Container>

            {/* Testimonials */}
            <Container className="mt-4 pt-4 bg-secondary">
                <Carousel className="testi-face">
                    {
                        testimonial.map((itemm, keyy) =>
                            <Carousel.Item  key={keyy}>
                                <Card>
                                    <Card.Img variant="top" src={image_path + itemm.image} />
                                    <Card.Body>
                                        <Card.Text>{itemm.message}</Card.Text>
                                        <Card.Title>{itemm.name}</Card.Title>
                                        <Card.Text>{itemm.designation}</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        )
                    }

                </Carousel>
            </Container>
        </>
    )
}

export default Home
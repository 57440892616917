import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"

function OfferCard(props) {

    const image_path = process.env.REACT_APP_IMAGE

    return (
        <Col md={4} xs={12}>
            <Card className="special-card">
                <Link to={`/singlepackage/`+props.itemm.id}>
                    <Card.Img variant="top" src={image_path + props.itemm.default_img} />
                    <Card.Body>
                        <Stack direction="horizontal" gap={3} className="mx-auto">
                            <div><i className="fas fa-thumbtack"></i> 
                            {
                                props.itemm.destinations.map((item1, keyy) => (
                                    <>{item1.destination.title}, </>
                                ))
                            }
                            </div>
                        </Stack>
                        <Card.Title className="text-primary text-line-2 mt-2">{props.itemm.title} Inclusive Deal {props.itemm.day}D/{props.itemm.night}N</Card.Title>
                        <Card.Text className="price">
                            Price : <span>₹{props.itemm.price}</span> ₹{props.itemm.offer}
                        </Card.Text>
                    </Card.Body>
                    <div className="disc align-items-center">
                        {props.itemm.discount && <div>{props.itemm.discount}%<br /><span>off</span></div>}
                    </div>
                </Link>
            </Card>
        </Col>
    )
}

export default OfferCard
import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"

function ActivityCard(props) {
    
    const image_path = process.env.REACT_APP_IMAGE

    return (
        <Col md={2} xs={6}>
            <Card className="align-items-center activi-card ">
                <Link to={'/packages/activity/' + props.itemm.id}>
                    <Card.Img variant="top" src={image_path + props.itemm.icon} alt="" />
                    <Card.Body className="text-center">
                        <Card.Title>{props.itemm.title}</Card.Title>
                        <Card.Text>{props.itemm.destinations_count} Destinations</Card.Text>
                    </Card.Body>
                </Link>
            </Card>
        </Col>
    )
}

export default ActivityCard
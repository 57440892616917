import { Card, Carousel, Col, Container, Row, Stack } from "react-bootstrap"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios';

import { scrollToTop } from '../utils/function'
import EnquiryForm from "../components/EnquiryForm";

function SingleHotel(props) {

    const image_path = process.env.REACT_APP_IMAGE

    const [data, setData] = useState([])
    const hotelid = useParams().hotelid;

    const getData = async (hotelid) => {
        try {
            const apipath = process.env.REACT_APP_API_URL
            const result = await axios.get(`${apipath}hotel/detail?hotelid=` + hotelid);
            const data = await result.data;
            setData(data.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData(hotelid)
    }, [hotelid])

    return (
        <>
            <Container className="mt-4">
                <Row>
                    <Col md={8} xs={12} sm={12}>
                        <div>
                            <h4 className="text-primary text-uppercase">{data.title}</h4>
                            <Stack direction="horizontal" gap={3} className="mx-auto pack_stat">
                                <div><i className="fas fa-thumbtack"></i> {data.destination && data.destination.title}</div>
                                <div className="vr" />
                                <div className="ratting">
                                    {data.rating} stars
                                    {/* <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i> */}
                                </div>
                            </Stack>
                        </div>
                    </Col>

                    <Col md={4} xs={12} sm={12}>
                        <div className="single_price">
                            <p>starting From</p>
                            <span className="price">₹ {data.price_range}</span>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={8} xs={12} sm={12}>
                        <Carousel className="single_packa">
                            {data.images && 
                                data.images.map((item1, key) => (
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={image_path + item1.image} alt="First slide" />
                                    </Carousel.Item>
                                ))
                            }
                        </Carousel>

                        <Tabs defaultActiveKey="description" className="mt-4">
                            <Tab eventKey="description" title="Description">
                                <div dangerouslySetInnerHTML={{__html: data.description}} />
                            </Tab>
                            <Tab eventKey="overview" title="Overview">
                                <p>Meal Type Available :</p>
                                <ul>
                                    {data.mealtypes && 
                                        data.mealtypes.map((item1, key) => (
                                            <li>{item1.mealtype.title}</li>
                                        ))
                                    }
                                </ul>
                                <p>Room Type :</p>
                                <ul>
                                    {data.roomtypes && 
                                        data.roomtypes.map((item1, key) => (
                                            <li>{item1.roomtype.title}</li>
                                        ))
                                    }
                                </ul>
                                <p>Amenities Available :</p>
                                <ul>
                                    {data.amenities && 
                                        data.amenities.map((item1, key) => (
                                            <li>{item1.amenity.title}</li>
                                        ))
                                    }
                                </ul>
                                <p>Activities :</p>
                                <ul>
                                    {data.activities && 
                                        data.activities.map((item1, key) => (
                                            <li>{item1.activity.title}</li>
                                        ))
                                    }
                                </ul>

                            </Tab>
                            <Tab eventKey="contact-detail" title="Contact Details">
                                <p>Contact Number : {data.contact}</p>
                                <p>Email Id : {data.email}</p>
                                <p>Address : {data.address}</p>
                            </Tab>
                            <Tab eventKey="map" title="Map">
                                <div dangerouslySetInnerHTML={{__html: data.map}} />
                            </Tab>
                        </Tabs>
                    </Col>

                    <Col md={4} xs={12} sm={12}>
                        <div className="bg-secondary">
                            <div className="enquir-heading">
                                <h5>Want to Go For A Memorable Holiday?</h5>
                                <p>Provide Your Details to Know Best Holiday Deals</p>
                            </div>
                            <div className="px-3 py-3">
                                <EnquiryForm type="Hotel" id={hotelid} title={data.title} />
                            </div>
                        </div>

                        <div className="bg-secondary mt-4 px-4 py-4">
                            <div className="section-heading">
                                <h5 className="dash-style">Need Help</h5>
                                <h4 className="text-primary text-uppercase">We are 24 x 7 available for you</h4>
                                <p>Discover various flavours of fun and take inspiration from our collections. We go above and beyond from any other average travel agency.</p>
                                <div className="text-center">
                                    <Link to={'tel:'+props.data.contactData.mobile} className="btn btn-call">Call Now</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SingleHotel

import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Link } from "react-router-dom"
import axios from 'axios';

import { scrollToTop } from '../utils/function'
import HotelCard from "../components/cards/HotelCard";

function HotelsDestination(props) {

    const image_path = process.env.REACT_APP_IMAGE
    
    const [destination, setDestination]     =   useState([])
    const [data, setData]       =   useState([])
    const destinationid         =   useParams().destinationid;
    
    const getData = async (destinationid) => {
        try {
            const apipath = process.env.REACT_APP_API_URL

            const result = await axios.get(`${apipath}destination/detail?destinationid=` + destinationid);
            const data = await result.data;
            setDestination(data.data);
            
            const result3 = await axios.get(`${apipath}hotel/particular_destinationwise?destinationid=`+destinationid);
            const data3 = await result3.data;
            setData(data3.data);
            console.log(data3.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData(destinationid)
    }, [destinationid])
    
    return (
        <>
           {destination ?
           <Container>
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Luxury Hotels at Affordable Prices</h5>
                            <h2 className="text-primary text-uppercase">Hotels in {destination.title}</h2>
                            <p>{props.data.contactData.description_destination}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-1 g-4">
                    {
                        data.map((itemm, key) =>
                            <HotelCard key={key} itemm={itemm} />
                        )
                    }
                </Row>
            </Container>
            : "No data is found."}
        </>
    )
}

export default HotelsDestination

export function checkToken()
{
    var path    =   "";
    var token   =   localStorage.getItem("token");
    if (token) 
    {
        var profile = localStorage.getItem("userProfile");
        if(profile) 
        {
            profile =   JSON.parse(profile);
            
            if (profile.role==='Reader') 
            {
                path = "/user-dashboard";
            }
            if (profile.role==='Author') 
            {
                path = "/author-dashboard";
            }
            if (profile.role==='Editor') 
            {
                path = "/editor-dashboard";
            }
        }
    }

    return path;
}

export function checkRole(menuPage)
{
    var redirect    =   "/";
    var menu        =   [];
    var token       =   localStorage.getItem("token");
    if (token) 
    {
        var profile =   localStorage.getItem("userProfile");
        if(profile) 
        {
            profile =   JSON.parse(profile);

            if (profile.role==='Reader') 
            {
                menu = ['/user-dashboard','/user-edit-profile','/user-change-password','/user-favarticle'];
             
                if(menu.indexOf(menuPage) > -1)
                {
                    redirect = "";
                }
            }
            if (profile.role==='Author') 
            {
                menu = ['/author-dashboard','/author-edit-profile','/author-change-password','/author-myarticle','/author-addarticle','/author-editarticle'];
              
                if(menu.indexOf(menuPage) > -1)
                {
                    redirect = "";
                }
            }
            if (profile.role==='Editor') 
            {
                menu = ['/editor-dashboard','/editor-edit-profile','/editor-change-password','/editor-articles','/editor-article-review','/editor-authors','/editor-author'];               
                
                if(menu.indexOf(menuPage) > -1)
                {
                    redirect = "";
                }
            }
        }
    }

    return redirect;
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

export const SeoTitle = (newTitle) => {
    return (document.title = newTitle);
}




import { Card, Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import axios from 'axios';
import { Link } from "react-router-dom"

import { scrollToTop } from '../utils/function'

import ActivityCard from './../components/cards/ActivityCard'

function AllActivities(props) {

    const image_path = process.env.REACT_APP_IMAGE
    
    const [activity, setActivity] = useState([]);
    
    const getData = async () => {
        try {
            const apipath = process.env.REACT_APP_API_URL

            const result3 = await axios.get(`${apipath}activity/all`);
            const data3 = await result3.data;
            setActivity(data3.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData()
    }, [])
    
    return (
        <>
            <Container>
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">TRAVEL BY ACTIVITY</h5>
                            <h2 className="text-primary text-uppercase">Adventure & Activity</h2>
                            <p>{props.data.contactData.description_activity}</p>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-3">
                    {
                        activity.map((itemm, keyy) =>
                            <ActivityCard itemm={itemm} />
                        )
                    }
                </Row>

            </Container>
        </>
    )
}

export default AllActivities

import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios';

import { scrollToTop } from '../utils/function'
import PackageCard from "../components/cards/PackageCard";

function PackagesDestination(props) {

    const image_path = process.env.REACT_APP_IMAGE
    
    const [destination, setDestination]     =   useState([])
    const [data, setData]                   =   useState([])
    const destinationid                     =   useParams().destinationid;
    
    const getData = async (destinationid) => {
        try {
            const apipath = process.env.REACT_APP_API_URL

            const result = await axios.get(`${apipath}destination/detail?destinationid=` + destinationid);
            const data = await result.data;
            setDestination(data.data);
            
            const result3 = await axios.get(`${apipath}package/destinationwise?destinationid=`+destinationid);
            const data3 = await result3.data;
            setData(data3.data);

        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData(destinationid)
    }, [destinationid])
    
    return (
        <>
           <Container className="mt-4 pt-4">
                {destination ?
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Explore Great Places</h5>
                            <h2 className="text-primary text-uppercase">{destination.title} Packages</h2>
                            <p>{props.data.contactData.description_destination}</p>
                        </div>
                    </Col>
                </Row>
                : "No data is found."}

                <Row className="mt-1 g-4">
                    {
                        data.map((itemm, key) =>
                            <PackageCard key={key} itemm={itemm} />
                        )
                    }
                </Row>

            </Container>
        </>
    )
}

export default PackagesDestination
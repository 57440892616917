import React from 'react';
import { useState } from "react";
import { Container, Nav, Navbar, NavDropdown, Image, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"

function Header(props) {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }

  const [show1, setShow1] = useState(false);
  const showDropdown1 = (e) => {
    setShow1(!show);
  }
  const hideDropdown1 = e => {
    setShow1(false);
  }
  
  const des =  props.data.destinationData;
  const act =  props.data.activityData;
  
  const image_path = process.env.REACT_APP_IMAGE

  return (
    <>
      <Container fluid className="px-5 d-none d-lg-block bg-dark top-headd">
        <Container>
          <Row className="gx-0">
            <Col md={8} className="text-lg-start mb-2 mb-lg-0">
              <div className="d-inline-flex align-items-center" style={{ marginTop: '5px' }}>
                <small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i>{props.data.contactData.mobile}</small>
                <small className="text-light"><i className="fa fa-envelope-open me-2"></i>{props.data.contactData.email}</small>
              </div>
            </Col>
            <Col md={4} className="text-lg-end">
              <div className="d-inline-flex align-items-center">
                <Link className="btn btn-sm btn-outline-light btn-sm-square me-2" target="_blank" to={props.data.contactData.social_facebook_link}><i className="fab fa-facebook-f fw-normal"></i></Link>
                <Link className="btn btn-sm btn-outline-light btn-sm-square me-2" target="_blank" to={props.data.contactData.social_twitter_link}><i className="fab fa-twitter fw-normal"></i></Link>
                <Link className="btn btn-sm btn-outline-light btn-sm-square me-2" target="_blank" to={props.data.contactData.social_linkedin_link}><i className="fab fa-linkedin-in fw-normal"></i></Link>
                <Link className="btn btn-sm btn-outline-light btn-sm-square me-2" target="_blank" to={props.data.contactData.social_instgram_link}><i className="fab fa-instagram fw-normal"></i></Link>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Navbar collapseOnSelect expand="lg" variant="light" className="main-menu shadow1">
        <Container>
          <Navbar.Brand><Link to='/'><Image src={image_path + props.data.contactData.company_logo}
           alt="myTripSpot" className="main-logo" /></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll" data-toggle="collapse" >
            <Nav className="ms-auto">
              <Link to='/'>Home</Link>
              {/* <Link to='/all-packages'>Holiday Packages</Link> */}
              <NavDropdown title="Holiday Packages" show={show} onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
                {
                  des.map((itemm,keyy)=>
                      <>
                      {itemm.packages_count>0 && 
                        <NavDropdown.Item key={keyy}><Link to={'/packages/destination/'+itemm.id}>{itemm.title}</Link></NavDropdown.Item>
                      }
                      </>
                      
                  )
                }
              </NavDropdown>
              <Link to='/all-hotels'>Hotels</Link>
              <NavDropdown title="Activities" show={show1} onMouseEnter={showDropdown1} onMouseLeave={hideDropdown1}>
                {
                  act.map((itemm,keyy)=>
                    <>
                    {itemm.destinations_count>0 && 
                      <NavDropdown.Item key={keyy}><Link to={'/packages/activity/'+itemm.id}>{itemm.title}</Link></NavDropdown.Item>
                    }
                    </>
                  )
                }
              </NavDropdown>
              <Link to='/all-offers'>Special Offers</Link>
              <Link to='/contact'>Contact</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

    </>
  )
}

export default Header

import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios';

import { scrollToTop } from '../utils/function'
import PackageCard from "../components/cards/PackageCard";

function PackagesActivity(props) {

    const image_path = process.env.REACT_APP_IMAGE
    
    const [activity, setActivity]   =   useState([])
    const [data, setData]           =   useState([])
    const activityid                =   useParams().activityid;
    
    const getData = async (activityid) => {
        try {
            const apipath = process.env.REACT_APP_API_URL

            const result = await axios.get(`${apipath}activity/detail?activityid=` + activityid);
            const data = await result.data;
            setActivity(data.data);
            
            const result3 = await axios.get(`${apipath}package/activitywise?activityid=`+activityid);
            const data3 = await result3.data;
            setData(data3.data);
            console.log(data3.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData(activityid)
    }, [activityid])
    
    return (
        <>
           <Container className="mt-4 pt-4">
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Explore Great Places</h5>
                            <h2 className="text-primary text-uppercase">Packages with {activity.title}</h2>
                            <p>{props.data.contactData.description_activity}</p>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-1 g-4">
                    {
                        data.map((itemm, key) =>
                            <PackageCard key={key} itemm={itemm} />
                        )
                    }
                </Row>

            </Container>
        </>
    )
}

export default PackagesActivity
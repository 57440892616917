import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"

function PackageCard(props) {
    const image_path = process.env.REACT_APP_IMAGE
    
    
    return (
        <Col md={4} xs={12} >
            <Card className="pack-card">
                <Link to={`/singlepackage/`+props.itemm.id}>
                    <Card.Img variant="top" src={image_path + props.itemm.default_img} />

                    <Card.Body>
                        <div className="pack-bar">
                            <Stack direction="horizontal" gap={3} className="mx-auto">
                                <div><i className="fas fa-clock"></i> {props.itemm.day} Days / {props.itemm.night} Nights</div>
                            </Stack>
                        </div>
                        <Card.Title className="text-primary text-line-2">{props.itemm.title}</Card.Title>
                        <Card.Text className="text-line-3">{props.itemm.short_desc}</Card.Text>
                        <Card.Text className="mb-0 destii">
                            <i className="fas fa-map-marker-alt"></i>
                            {
                                props.itemm.destinations.map((item1, keyy) => (
                                    <><span>{item1.destination.title}</span> </>
                                ))
                            }
                        </Card.Text>
                        <div className="price">₹{props.itemm.price} <span>/ per person</span></div>
                    </Card.Body>
                </Link>
            </Card>
        </Col>
    )
}

export default PackageCard
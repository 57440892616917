import { Button, Col, Container, Form, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import { scrollToTop } from '../utils/function'

function Contact(props) {
    
  const navigate = useNavigate();
  const initialValues               = { name: "", emailid: "", contact: "", city: "", subject: "", message: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit]     = useState(false);

  const [name, setName]             = useState("");
  const [emailid, setEmailid]       = useState("");
  const [contact, setContact]       = useState("");
  const [city, setCity]             = useState("");
  const [subject, setSubject]       = useState("");
  const [message, setMessage]       = useState("");

  const [disabled, setDisabled]     = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.emailid) {
      errors.emailid = "Email is required!";
    } else if (!regex.test(values.emailid)) {
      errors.emailid = "This is not a valid email format!";
    }
    if (!values.subject) {
      errors.subject = "Subject is required!";
    }
    if (!values.message) {
        errors.message = "Message is required!";
      }
    
    return errors;
  };


  const setApi = async (data) => {
    try {
        const apipath = process.env.REACT_APP_API_URL
        //setDisabled(true);
      
      const result1 = await axios.post(`${apipath}enquiry/add`, data);
      const data1   = await result1.data;
      console.log(data1);
      if (data1.bool === true) 
      {
         navigate('/thank-you-for-contact');
      }
      else 
      {
        setDisabled(false);
        alert("something went wrong!");
        //setFormErrors({ credential: "something went wrong!" });
      }

    }
    catch (error) {
      //console.log(error)
      setFormErrors({ credential: error });
    }
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setApi(formValues)
    }
  }, [formErrors]);


  useEffect(() => {
    scrollToTop();
}, [])


    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(/images/about.jpeg)` }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Contact Us</h1>
                        <span className="h5 text-white">We are available 24 x 7</span>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="py-5">
                <Container className="py-3">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto">
                        <h5 className="fw-bold text-primary text-uppercase">Contact Us</h5>
                        <h1 className="mb-0">If You Have Any Query, Feel Free To Contact Us</h1>
                    </div>
                    <Row className="g-5">
                        <Col md={4} sm={12}>
                            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded widhgt60">
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h6 className="mb-2">Call to ask any question</h6>
                                    <h6 className="text-primary mb-0">{props.data.contactData.mobile}</h6>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.4s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded widhgt60">
                                    <i className="fa fa-envelope-open text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h6 className="mb-2">Email to get free quote</h6>
                                    <h6 className="text-primary mb-0">{props.data.contactData.email}</h6>
                                </div>
                            </div>
                        </Col>
                        <Col md={4} sm={12}>
                            <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.8s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded widhgt60">
                                    <i className="fa fa-map-marker-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h6 className="mb-2">Visit our office</h6>
                                    <h6 className="text-primary mb-0">{props.data.contactData.address}</h6>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <form onSubmit={handleSubmit}>
                        <Row className="g-5 mt-5">
                            <Col md={6} sm={12}>
                                
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Control type="text" placeholder="Full Name" className="bg-light border-0" style={{ height: '55px' }} name="name" onChange={handleChange} required />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Control type="email" placeholder="Email Id" className="bg-light border-0" style={{ height: '55px' }} name="emailid" onChange={handleChange} required />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Control type="text" placeholder="Contact Number" className="bg-light border-0" style={{ height: '55px' }} name="contact" onChange={handleChange} required />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Control type="text" placeholder="City" className="bg-light border-0" style={{ height: '55px' }} name="city" onChange={handleChange} required />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Control type="text" placeholder="Subject" className="bg-light border-0" style={{ height: '55px' }} name="subject" onChange={handleChange} required />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Control as="textarea" rows={3} className="bg-light border-0" name="message" onChange={handleChange} required />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button variant="primary" type="submit" className="mt-3 text-white">
                                                Send Message
                                            </Button>
                                        </Col>
                                    </Row>
                                
                            </Col>
                            <Col md={6} sm={12}>
                                <div dangerouslySetInnerHTML={{__html: props.data.contactData.company_map}} />
                            </Col>
                        </Row>
                    </form>

                </Container>
            </Container>
        </>
    )
}

export default Contact
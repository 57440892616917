import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"

function HotelCard(props) {
    const image_path = process.env.REACT_APP_IMAGE


    return (
        <Col md={4} xs={12}>
            <Card className="hotel-card">
                <Link to={`/singlehotel/`+props.itemm.id}>
                    <Card.Img variant="top" src={image_path + props.itemm.default_img} />
                    <Card.Body>
                        <Card.Title className="text-primary text-line-1">{props.itemm.title}</Card.Title>
                        <Card.Text>
                            <div className="d-flex">
                                <div className="rattingss me-auto">
                                    {props.itemm.rating} star
                                    
                                    {/* <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i>
                                    <i className="fa fa-star"></i> */}
                                </div>
                                <div className="price ml-auto"><span>starting from </span>₹{props.itemm.price_range}</div>
                            </div>
                            <div className="text-line-2">{props.itemm.short_desc}</div>
                        </Card.Text>

                    </Card.Body>
                </Link>
            </Card>
        </Col>
    )
}

export default HotelCard
import { Col, Container, Row } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios';

import { scrollToTop } from '../utils/function'

function Contact() {

    const image_path            =   process.env.REACT_APP_IMAGE

    const [data, setData]       =   useState([])
    const contentid             =   useParams().contentid;
    
    const getData = async(contentid) => {
        try {
            const apipath = process.env.REACT_APP_API_URL
            const result = await axios.get(`${apipath}content/detail?contentid=`+contentid);
            const data   = await result.data;
            setData(data.data);
          }
          catch (error) {
          }
    }

    useEffect(() => {
        scrollToTop()
        getData(contentid)
    }, [contentid])
    
    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(${image_path+data.banner})` }}>
                <Container>
                    <Row>
                        <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">{data.title}</h1>
                            <span className="h5 text-white">MyTripSpot</span>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container className="mt-2 mb-4">
                <Row>
                    <Col md={12} xs={12} className="mt-4 pt-4">
                        <div className="section-heading">
                            <h5 className="dash-style">My Trip Spot</h5>
                            <h2 className="text-primary text-uppercase">{data.title}</h2>
                        </div>
                        <div className="mb-4 mt-4 text-justify">
                            <div dangerouslySetInnerHTML={{__html: data.description}} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Contact
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function EnquiryForm(props) {

    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();
        
        var c_name      = document.getElementById('name').value;
        var c_email     = document.getElementById('emailid').value;
        var c_contact   = document.getElementById('contact').value;
        var c_no_people = document.getElementById('no_people').value;
        var c_preferred_date = document.getElementById('preferred_date').value;
        var c_package_id = '';
        var c_hotel_id = '';
        
        if(props.type=='Package')
        {
            c_package_id  = props.id;
            c_hotel_id    = "";
        }

        if(props.type=='Hotel')
        {
            c_hotel_id    = props.id;
            c_package_id  = "";
        }
        
        var data = { name: c_name, emailid: c_email, contact: c_contact, enquiry_type: props.type, 
            package_id: c_package_id, hotel_id: c_hotel_id,
            no_people: c_no_people, preferred_date: c_preferred_date };
        setApi(data);
      };

    const setApi = async (data) => {
        try {
            const apipath = process.env.REACT_APP_API_URL
            
          const result1 = await axios.post(`${apipath}enquiry/add`, data);
          const data1   = await result1.data;
          console.log(data1);
          if (data1.bool === true) 
          {
            navigate('/thank-you-for-contact');
          }
          else 
          {
            alert("something went wrong!");
            //setFormErrors({ credential: "something went wrong!" });
          }
    
        }
        catch (error) {
           console.log(error)
        }
      }
    
    
    
    return (
        <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
                <Form.Group>
                    <Form.Label>{props.type} Name</Form.Label>
                    <Form.Control disabled placeholder={props.title} />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Name" name="name" id="name" required />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control placeholder="eg. xxxx xxx xxx" name="contact" id="contact" required />
                </Form.Group>
            </Row>

            <Form.Group className="mb-3">
                <Form.Label>Email Id</Form.Label>
                <Form.Control type="email" placeholder="Enter email" name="emailid" id="emailid" required />
            </Form.Group>

            <Row className="mb-3">
                <Form.Group as={Col}>
                    <Form.Label>Date of Departure</Form.Label>
                    <Form.Control type="date" placeholder="Enter Name" name="preferred_date" id="preferred_date" />
                </Form.Group>

                <Form.Group as={Col}>
                    <Form.Label>Number of People</Form.Label>
                    <Form.Control placeholder="eg. 1,2,3,4" name="no_people" id="no_people" />
                </Form.Group>
            </Row>

            <Form.Group className="mb-3" id="formGridCheckbox">
                <Form.Check type="checkbox" label="Require Transport" />
            </Form.Group>
            <Form.Group className="mb-3" id="formGridCheckbox">
                <Button variant="outline-main" className='wth100' type="submit">
                    Send Enquiry
                </Button>
            </Form.Group>
        </Form>
    )
}

export default EnquiryForm
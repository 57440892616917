import { Card, Col, Container, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"

function DestinationCard(props) {

    const image_path = process.env.REACT_APP_IMAGE

    return (
        <Col md={3} className="p-3">
            <Link to={`/packages/destination/`+props.itemm.id}>
                <Card className="bg-dark text-white desti-card">
                    <Card.Img src={image_path + props.itemm.image} alt="Card image" />
                    <Card.ImgOverlay>
                        <Card.Title>{props.itemm.title}</Card.Title>
                        <Card.Text>{props.itemm.packages_count} Packages</Card.Text>
                    </Card.ImgOverlay>
                </Card>
            </Link>
        </Col>
    )
}

export default DestinationCard

import { Col, Container, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"
import React, { useEffect, useState } from "react"
import axios from 'axios';

import { scrollToTop } from '../utils/function'

function AllHotels(props) {

    const [hotel, setHotel] = useState([]);
    
    const getData = async () => {
        try {
            const apipath = process.env.REACT_APP_API_URL

            const result3 = await axios.get(`${apipath}hotel/all_destinationwise`);
            const data3 = await result3.data;
            setHotel(data3.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData()
    }, [])
    
    return (
        <>
            <Container className="mt-4 pt-4">
                <Row className="mt-4 text-center">
                    <Col md={{ span: 8, offset: 2 }} xs={12}>
                        <div className="section-heading">
                            <h5 className="dash-style">Luxury in Affordable Prices</h5>
                            <h2 className="text-primary text-uppercase">Best Hotel Deals</h2>
                            <p>{props.data.contactData.description_hotel}</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {
                        hotel.map((itemm, keyy) =>
                            <>
                                {itemm.hotels_count>0 && 
                                <Col md={4}>
                                    <Link to={`/hotels/destination/`+itemm.id}>
                                        <div className="hotelName">Hotels in {itemm.title}</div>
                                    </Link>
                                </Col>}
                            </>
                        )
                    }
                </Row>
            </Container>
        </>
    )
}

export default AllHotels
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "react-router-dom"

function Footer(props) {
    
    const image_path = process.env.REACT_APP_IMAGE
    const content =  props.data.contentData;
     
    return (
        <>
            <Container fluid className="bg-dark text-light mt-2">
                <Container>
                    <Row className="gx-5">

                        <Col md={4} sm={6} className="footer-about">
                            <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                                <Link to="/" className="navbar-brand">
                                    <img src={image_path + props.data.contactData.company_logo} style={{ width: '50%' }} alt="MyTripSpot" />
                                </Link>
                                <p className="mt-3 mb-4">{props.data.contactData.footer_company_content} </p>
                                <Link to="/">mytripspot.in</Link>
                            </div>
                        </Col>

                        <Col md={8} sm={6} className="footer-link">
                            <Row className="gx-5">

                                <Col md={4} sm={12} className="pt-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Get In Touch</h3>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-geo-alt text-primary me-2"></i>
                                        <p className="mb-0">{props.data.contactData.address}</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"></i>
                                        <p className="mb-0">{props.data.contactData.email}</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <p className="mb-0">{props.data.contactData.mobile}</p>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <Link className="btn btn-primary btn-square me-2" target="_blank" to={props.data.contactData.social_facebook_link}><i className="fab fa-facebook-f fw-normal"></i></Link>
                                        <Link className="btn btn-primary btn-square me-2" target="_blank" to={props.data.contactData.social_twitter_link}><i className="fab fa-twitter fw-normal"></i></Link>
                                        <Link className="btn btn-primary btn-square me-2" target="_blank" to={props.data.contactData.social_linkedin_link}><i className="fab fa-linkedin-in fw-normal"></i></Link>
                                        <Link className="btn btn-primary btn-square" target="_blank" to={props.data.contactData.social_instgram_link}><i className="fab fa-instagram fw-normal"></i></Link>
                                    </div>
                                </Col>

                                <Col md={4} sm={12} className="pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Quick Links</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <Link className="text-light mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                                        {
                                            content.map((itemm,keyy)=>
                                                <Link key={keyy} className="text-light mb-2" to={'/content/'+itemm.id}><i className="bi bi-arrow-right text-primary me-2"></i>{itemm.title}</Link>
                                            )
                                        }
                                    </div>
                                </Col>

                                <Col md={4} sm={12} className="pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Service Links</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <Link className="text-light mb-2" to="/all-destinations"><i className="bi bi-arrow-right text-primary me-2"></i>All Destinations</Link>
                                        <Link className="text-light mb-2" to="/all-activities"><i className="bi bi-arrow-right text-primary me-2"></i>All Activites</Link>
                                        <Link className="text-light mb-2" to="/all-packages"><i className="bi bi-arrow-right text-primary me-2"></i>Holiday Packages</Link>
                                        <Link className="text-light mb-2" to="/all-offers"><i className="bi bi-arrow-right text-primary me-2"></i>Special Offers</Link>
                                        <Link className="text-light" to="/contact"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</Link>
                                    </div>
                                </Col>

                            </Row>
                        </Col>

                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default Footer
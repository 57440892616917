import { Card, Carousel, Col, Container, Row, Stack } from "react-bootstrap"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import axios from 'axios';

import { scrollToTop } from '../utils/function'
import EnquiryForm from "../components/EnquiryForm";

function SinglePackage(props) {

    const image_path = process.env.REACT_APP_IMAGE

    const [data, setData] = useState([])
    const packageid = useParams().packageid;

    const getData = async (packageid) => {
        try {
            const apipath = process.env.REACT_APP_API_URL
            const result = await axios.get(`${apipath}package/detail?packageid=` + packageid);
            const data = await result.data;
            setData(data.data);
        }
        catch (error) {
        }
    }

    useEffect(() => {
        scrollToTop()
        getData(packageid)
    }, [packageid])

    return (
        <>
            <Container className="mt-4">
                <Row>
                    <Col md={8} xs={12} sm={12}>
                        <div>
                            <h4 className="text-primary text-uppercase">{data.title}</h4>
                            <Stack direction="horizontal" gap={3} className="mx-auto pack_stat">
                                <div><i className="fas fa-clock"></i> {data.day} Days / {data.night} Nights</div>
                                <div className="vr" />
                                <div><i className="fas fa-utensils"></i>{data.mealtype && data.mealtype.title }</div>
                                <div className="vr" />
                                <div><i className="fas fa-thumbtack"></i> 
                                {data.destinations && 
                                    data.destinations.map((item1, key) => (
                                        <>{item1.destination.title}, </>
                                    ))
                                }
                                </div>
                            </Stack>
                        </div>
                    </Col>
                    
                    <Col md={4} xs={12} sm={12}>
                        
                        {data.offer==0?
                            <div className="single_price">
                                <p>starting From</p>
                                <span className="price">₹ {data.price}</span>
                                <p>Per Person on twin sharing</p>
                            </div>
                        :
                            <div className="single_price">
                                <p>starting From</p>
                                <span className="price"><span>₹ {data.price}</span>₹ {data.offer}</span>
                                <p>Per Person on twin sharing</p>
                            </div>
                        }
                        
                        
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={8} xs={12} sm={12}>
                        <Carousel className="single_packa">
                            {data.images && 
                                data.images.map((item1, key) => (
                                    <Carousel.Item>
                                        <img className="d-block w-100" src={image_path + item1.image} alt="First slide" />
                                    </Carousel.Item>
                                ))
                            }
                        </Carousel>

                        <Tabs defaultActiveKey="description" className="mt-4">
                            <Tab eventKey="description" title="Description">
                                <div dangerouslySetInnerHTML={{__html: data.description}} />
                            </Tab>
                            <Tab eventKey="summary" title="Summary">
                                <div dangerouslySetInnerHTML={{__html: data.summary}} />
                            </Tab>
                            <Tab eventKey="map" title="Map">
                                <div dangerouslySetInnerHTML={{__html: data.map}} />
                            </Tab>
                        </Tabs>
                    </Col>

                    <Col md={4} xs={12} sm={12}>
                        <div className="bg-secondary">
                            <div className="enquir-heading">
                                <h5>Want to Go For A Memorable Holiday?</h5>
                                <p>Provide Your Details to Know Best Holiday Deals</p>
                            </div>
                            <div className="px-3 py-3">
                                <EnquiryForm type="Package" id={packageid} title={data.title} />
                            </div>
                        </div>

                        <div className="bg-secondary mt-4 px-4 py-4">
                            <div className="section-heading">
                                <h5 className="dash-style">Need Help</h5>
                                <h4 className="text-primary text-uppercase">We are 24 x 7 available for you</h4>
                                <p>Discover various flavours of fun and take inspiration from our collections. We go above and beyond from any other average travel agency.</p>
                                <div className="text-center">
                                    <Link to={'tel:'+props.data.contactData.mobile} className="btn btn-call">Call Now</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            {/* <Container className="mt-4 mb-4 pb-4 pt-4 text-center">
                <div className="section-heading">
                    <h5 className="dash-style">Some more options</h5>
                    <h2 className="text-primary text-uppercase">Similar Packages</h2>
                    <p>Discover various flavours of fun and take inspiration from our collections. We go above and beyond from any other average travel agency.</p>
                </div>

                <Row className="mt-1 g-4">
                    <Col md={4} xs={12}>
                        <Card className="pack-card">
                            <Link to="/singlepackage">
                                <Card.Img variant="top" src="/images/goa-p.jpg" />
                                <Card.Body>
                                    <div className="pack-bar">
                                        <Stack direction="horizontal" gap={3} className="mx-auto">
                                            <div><i className="fas fa-clock"></i> 4Days / 3Nights</div>
                                            <div className="vr" />
                                            <div><i className="fas fa-thumbtack"></i> Goa</div>
                                        </Stack>
                                    </div>
                                    <Card.Title className="text-primary text-line-2">Amazing Goa Flight Inclusive Deal 4D/3N</Card.Title>
                                    <Card.Text className="text-line-3">
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                    </Card.Text>
                                    <div className="price">₹22,999 <span>/ per person</span></div>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>

                    <Col md={4} xs={12}>
                        <Card className="pack-card">
                            <Link to="/singlepackage">
                                <Card.Img variant="top" src="/images/kerala-p.jpg" />
                                <Card.Body>
                                    <div className="pack-bar">
                                        <Stack direction="horizontal" gap={3} className="mx-auto">
                                            <div><i className="fas fa-clock"></i> 5Days / 4Nights</div>
                                            <div className="vr" />
                                            <div><i className="fas fa-thumbtack"></i> Kerala</div>
                                        </Stack>
                                    </div>
                                    <Card.Title className="text-primary text-line-2">Experience the natural beauty of Kerala</Card.Title>
                                    <Card.Text className="text-line-3">
                                        A hot favourite of all nature lovers, your next Kerala trip is going to be an unforgettable one!
                                    </Card.Text>
                                    <div className="price">₹31,999 <span>/ per person</span></div>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>

                    <Col md={4} xs={12}>
                        <Card className="pack-card">
                            <Link to="/singlepackage">
                                <Card.Img variant="top" src="/images/jaisalmer-p.jpg" />
                                <Card.Body>
                                    <div className="pack-bar">
                                        <Stack direction="horizontal" gap={3} className="mx-auto">
                                            <div><i className="fas fa-clock"></i> 5Days / 4Nights</div>
                                            <div className="vr" />
                                            <div><i className="fas fa-thumbtack"></i> Jaisalmer</div>
                                        </Stack>
                                    </div>
                                    <Card.Title className="text-primary text-line-2">The Desert Camps of Jaisalmer</Card.Title>
                                    <Card.Text className="text-line-3">
                                        Rajasthan has numerous objects of antiquarian significance, including massive forts and exquisitely beautiful palaces built by the Hindu Rajput rulers of the region.
                                    </Card.Text>
                                    <div className="price">₹19,999 <span>/ per person</span></div>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>
                </Row>
            </Container> */}
        </>
    )
}

export default SinglePackage
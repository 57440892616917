import { Button, Col, Container, Form, Row } from "react-bootstrap"
import React from "react"

function Thankyou(props) {

    return (
        <>
            <Container fluid className="bg-header" style={{ backgroundImage: `url(/images/about.jpeg)` }}>
                <Row>
                    <Col md={12} className="pt-lg-5 mt-lg-5 mb-lg-5 pb-lg-5 text-center">
                        <h1 className="display-4 text-white animated zoomIn">Thank You for getting in touch!</h1>
                    </Col>
                </Row>
            </Container>

            <Container fluid className="py-5" >
                <Container className="py-3">
                    <div className="section-title text-center position-relative pb-3 mx-auto">
                        <h3 className="mb-0 ">We have received your message and would like to thank you for writing to us.</h3>
                        <p>If your inquiry is urgent, please use the telephone number listed below to talk to one of our staff members.
                            <br />
                            Otherwise, we will reply by email as soon as possible.</p>
                    </div>

                    <div className="d-flex align-items-center wow fadeIn" data-wow-delay="0.1s">
                        <div className="bg-primary d-flex align-items-center ms-auto justify-content-center rounded widhgt40">
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ps-3 me-auto">
                            <h4 className="text-primary mb-0">{props.data.contactData.mobile}</h4>
                        </div>
                    </div>

                </Container>
            </Container>
        </>
    )
}

export default Thankyou
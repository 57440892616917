import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from "react";
import axios from 'axios';

import './App.css';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

import Home from './pages/Home';

import AllDestinations from './pages/AllDestinations';
import AllActivities from './pages/AllActivities';
import AllPackages from './pages/AllPackages';
import AllOffers from './pages/AllOffers';
import PackagesDestination from './pages/PackagesDestination';
import PackagesActivity from './pages/PackagesActivity';

import AllHotels from './pages/AllHotels';
import HotelsDestination from './pages/HotelsDestination';

import SingleContent from './pages/SingleContent';
import SinglePackage from './pages/SinglePackage';
import SingleHotel from './pages/SingleHotel';

import Contact from './pages/Contact';
import Thankyou from './pages/Thankyou';

function App() {
  
  const [contactData, setContactData]          = useState([])
  const [destinationData, setDestinationData]  = useState([])
  const [activityData, setActivityData]        = useState([])
  const [contentData, setContentData]          = useState([])
  
  const getData = async () => {
    try {
      const apipath = process.env.REACT_APP_API_URL
      
      const result1 = await axios.get(`${apipath}setting/all`);
      const data1   = await result1.data;
      setContactData(data1.data);
      
      const result2 = await axios.get(`${apipath}destination/all`);
      const data2   = await result2.data;
      setDestinationData(data2.data);
      
      const result3 = await axios.get(`${apipath}activity/all`);
      const data3   = await result3.data;
      setActivityData(data3.data);

      const result4 = await axios.get(`${apipath}content/all`);
      const data4   = await result4.data;
      setContentData(data4.data);
    }
    catch (error) {
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
    <BrowserRouter>
      <Header data={{ contactData, destinationData, activityData }} />
      <Routes>

        <Route path='/' element={<Home data={{ contactData, destinationData, activityData }} />} />
        
        <Route path='/all-destinations' element={<AllDestinations data={{ contactData }}  />} />
        <Route path='/all-activities' element={<AllActivities data={{ contactData }}  />} />
        
        <Route path='/all-packages' element={<AllPackages data={{ contactData }} />} />
        <Route path='/packages/destination/:destinationid' element={<PackagesDestination data={{ contactData }} />} />
        <Route path='/packages/activity/:activityid' element={<PackagesActivity data={{ contactData }} />} />
        
        <Route path='/all-hotels' element={<AllHotels data={{ contactData }} />} />     
        <Route path='/hotels/destination/:destinationid' element={<HotelsDestination data={{ contactData }} />} />
        
        <Route path='/all-offers' element={<AllOffers data={{ contactData }} />} />     
        
        <Route path='/content/:contentid' element={<SingleContent data={{ contactData }} />} />      
        <Route path='/singlepackage/:packageid' element={<SinglePackage data={{ contactData }}  />} />      
        <Route path='/singlehotel/:hotelid' element={<SingleHotel data={{ contactData }}  />} />      
        
        <Route path='/contact' element={<Contact data={{ contactData }} />} /> 
        <Route path='/thank-you-for-contact' element={<Thankyou data={{ contactData }} />} /> 

      </Routes>
      <Footer data={{ contactData, destinationData, activityData, contentData }} />
    </BrowserRouter>

    </>
  );
}

export default App;

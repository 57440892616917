import { Card, Col, Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import Carousel from './reuse/Carousel'

import DestinationCard from './cards/DestinationCard'

function PopularDestination(props) {
    
    const image_path = process.env.REACT_APP_IMAGE

    return (
        <>
            <Row className="mt-4 align-items-end">
                <Col md={7} xs={12}>
                    <div className="section-heading">
                        <h5 className="dash-style">Popular Destination</h5>
                        <h2 className="text-primary text-uppercase">Top Notch Destinations</h2>
                    </div>
                </Col>
                <Col md={5} xs={12}>
                    <p>{props.alldata.data.contactData.description_destination}</p>
                </Col>
            </Row>

            <Row>
                <Carousel show={4}>
                    {
                        props.destination.map((itemm, keyy) =>
                            <>
                                {itemm.packages_count>0 && <DestinationCard key={keyy} itemm={itemm} />}    
                            </>
                        )
                    }

                </Carousel>
            </Row>
        </>
    )
}

export default PopularDestination